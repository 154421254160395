<template>
	<div>
		<b-button
			v-b-modal.new-sub-project-modal
			v-b-tooltip.hover.left="`Add Sub Project`"
			variant="primary"
			class="btn-icon rounded-circle new-btn"
			size="lg"
			@click="key++"
		>
			<feather-icon icon="PlusIcon" size="20" />
		</b-button>
		<b-modal
			id="new-sub-project-modal"
			ref="newSubProjectModal"
			:title="isEdit ? `Edit item` : `Add new item`"
			centered
			@hidden="resetModal"
		>
			<validation-observer ref="formRules">
				<b-form ref="form">
					<b-form-group label="Sub project Name" label-for="item-name">
						<validation-provider #default="{ errors }" name="Sub project Name" rules="required">
							<b-form-input
								id="item-name"
								v-model="formData.name"
								type="text"
								:state="errors.length > 0 ? false : null"
								placeholder="Sub project Name"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group label="Technician" label-for="technician">
						<validation-provider #default="{ errors }" name="Technician" rules="required">
							<v-select
								id="technician"
								v-model="formData.technician_id"
								label="name"
								:options="technicians"
								:reduce="(o) => o.id"
								:state="errors.length > 0 ? false : null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-form>
			</validation-observer>

			<template #modal-footer="{ cancel }">
				<b-button variant="outline-secondary" @click="cancel">Cancel</b-button>
				<loading-btn variant="primary" :loading="loading" @click="handleOk">Save</loading-btn>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormGroup, BForm, BButton, VBTooltip, VBModal } from 'bootstrap-vue';
import { required } from '@validations';
import vSelect from 'vue-select';
import LoadingBtn from '@/components/LoadingBtn.vue';
import store from '@/store';

export default {
	name: 'SubProjectForm',

	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BButton,
		LoadingBtn,

		vSelect
	},

	directives: {
		'b-modal': VBModal,
		'b-tooltip': VBTooltip
	},

	props: {
		data: {
			type: Object,
			default: () => ({
				name: '',
				technician_id: ''
			})
		}
	},

	data: () => ({
		key: 0,
		technicians: [],
		required,
		loading: false
	}),

	computed: {
		formData() {
			return this.data;
		},

		isEdit() {
			return !!this.data.id;
		}
	},

	async created() {
		this.technicians = (await store.dispatch('users/getUsers', { type: 12 })).data;
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			this.loading = true;

			try {
				const data = {
					sub_projects: [
						{
							...this.formData,
							project_id: this.$route.params.id
						}
					]
				};

				if (this.isEdit) {
					await store.dispatch('subProjects/updateSubProject', { id: this.formData.id, data });
				} else {
					await store.dispatch('subProjects/addSubProject', { projectId: this.$route.params.id, data });
				}

				this.loading = false;
			} catch (error) {
				console.log(error);
				this.loading = false;
			}

			// Hide the modal manually
			this.$nextTick(() => {
				// eslint-disable-next-line no-unused-expressions
				this.$refs.newSubProjectModal?.toggle();
			});

			this.$emit('done');
		}
	}
};
</script>

<style lang="scss" scoped>
.new-btn {
	position: fixed;
	bottom: 3%;
	right: 2%;
	z-index: 999;
}
</style>
