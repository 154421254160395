<template>
	<div v-if="!loading">
		<template v-if="subProjects.length !== 0">
			<b-row>
				<b-col v-for="project in subProjects" :key="project.id" cols="12" sm="6" md="4" lg="3">
					<b-card class="report-card">
						<span>ID . {{ project.id }}</span>
						<b-card-title>
							{{ project.name }}
						</b-card-title>
						<b-card-text>
							<p class="mb-50">Technician</p>
							<div class="technician d-flex justify-content-between py-75">
								<h5 class="mb-0">{{ project.technician.name }}</h5>
								<p class="mb-0">{{ project.technician.phone }}</p>
							</div>
						</b-card-text>
						<div class="d-flex justify-content-between">
							<b-button
								v-b-modal.new-sub-project-modal
								v-b-tooltip.hover.top="'Edit'"
								variant="outline-secondary"
								class="btn-icon cursor-pointer"
								size="sm"
								@click="editSubProject(project)"
							>
								<feather-icon icon="Edit3Icon" size="16" />
							</b-button>
							<b-button size="sm" class="ml-50" variant="outline-primary" @click="openSubProject(project.id)">
								Details
							</b-button>
						</div>
					</b-card>
				</b-col>
			</b-row>
		</template>
		<div v-else class="text-muted text-center">
			<div class="pt-3">
				<feather-icon icon="AlertCircleIcon" size="56" />
				<p class="mb-2 mt-2">
					There's no sub project in this project yet!
				</p>
				<b-button v-b-modal.new-sub-project-modal variant="outline-primary">Add New Sub Project</b-button>
			</div>
		</div>

		<sub-project-form :data="editData" @done="handleDone" @hidden="resetForm" />
	</div>
	<div v-else class="">
		<div class="text-center text-primary mt-3 mb-2">
			<b-spinner class="align-middle" style="width: 3rem; height: 3rem;"></b-spinner>
			<span class="d-block mt-1">Loading...</span>
		</div>
	</div>
</template>

<script>
import { BRow, BCol, BButton, BSpinner, BCard, BCardTitle, BCardText, VBModal, VBTooltip } from 'bootstrap-vue';
import store from '@/store';
import SubProjectForm from './sub-project-form.vue';

export default {
	name: 'SubProjects',

	components: { BRow, BCol, BButton, BSpinner, BCard, BCardTitle, BCardText, SubProjectForm },

	directives: {
		'b-modal': VBModal,
		'b-tooltip': VBTooltip,
	},

	props: {
		id: {
			type: [String, Number],
			required: true,
		},
	},

	data: () => ({
		subProjects: [],
		editData: undefined,
		loading: false,
	}),

	async created() {
		this.loadSubProjects();
	},

	methods: {
		async loadSubProjects() {
			this.loading = true;

			try {
				this.subProjects = await store.dispatch('subProjects/getSubProjects', this.$route.params.id);
				this.loading = false;
			} catch (error) {
				console.error(error);
				this.loading = false;
			}
		},

		openSubProject(projectId) {
			this.$router.push({
				name: 'project.cfw.sub-project.details',
				params: { id: this.$route.params.id, subId: projectId },
			});
		},

		editSubProject(subProject) {
			this.editData = subProject;
		},

		handleDone() {
			this.resetForm();
			this.loadSubProjects();
		},

		resetForm() {
			this.editData = undefined;
		},
	},
};
</script>

<style lang="scss" scoped>
.report-card {
	border-right: 5px solid #8bc53f;

	.technician {
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
	}
}
</style>
